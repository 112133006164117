import {
  Component,
  createEffect,
  createMemo,
  createSignal,
  Match,
  onMount,
  Show,
  Switch,
} from 'solid-js'
import { Page } from '/web/Layout'
import { characterStore, chatStore, tagStore, userStore } from '/web/store'
import { useParams } from '@solidjs/router'
import PageHeader from '/web/shared/PageHeader'
import { getAssetUrl, setComponentPageTitle } from '/web/shared/util'
import { baseSortOptions, Chats, toChatListState } from '../Character/ChatList'
import TextInput from '/web/shared/TextInput'
import Button from '/web/shared/Button'
import { SortDirection, SortType } from '../Character/util'
import { Image, LayoutList, SortAsc, SortDesc } from 'lucide-solid'
import { ManualPaginate, usePagination } from '/web/shared/Paginate'
import Select from '/web/shared/Select'
import Loading from '/web/shared/Loading'
import Tabs, { useTabs } from '/web/shared/Tabs'
import { Characters, getSortFunction, sortOptions } from '../Character/CharacterList'
import { SortField, ViewType } from '../Character/components/types'
import TagSelect from '/web/shared/TagSelect'
import { AppSchema } from '/common/types'

const NoChats: Component = () => {
  const state = chatStore()
  return (
    <Show
      when={state.loaded}
      fallback={
        <div class="flex w-full justify-center">
          <Loading />
        </div>
      }
    >
      <div class="flex w-full justify-center text-xl">
        <div>This user has no public conversations.</div>
      </div>
    </Show>
  )
}

const UserProfilePage: Component = () => {
  const state = characterStore()
  const params = useParams()

  const [chatSearch, setChatSearch] = createSignal('')
  const [sortDirection, setSortDirection] = createSignal('desc' as SortDirection)
  const [sortField, setSortField] = createSignal('chat-updated' as SortType)

  const chatState = chatStore((s) => ({
    charsMap: s.allChars.map,
    chars: s.allChars.pubChars.filter((c) => {
      c.userId === params.id
    }),
    publicChats: s.publicChats.map((chat) => ({
      _id: chat._id,
      name: chat.name,
      createdAt: chat.createdAt,
      updatedAt: chat.updatedAt,
      characterId: chat.characterId,
      characters: toChatListState(s.allChars.map, chat),
      messageCount: chat.messageCount,
      userId: chat.userId,
    })),
    loaded: s.loaded,
  }))

  const [characters, setCharacters] = createSignal<AppSchema.Character[]>(chatState.chars)

  const chats = createMemo(() => {
    return chatState.publicChats.filter((chat) => {
      const trimmed = chatSearch().trim().toLowerCase()
      if (!trimmed) return true
      if (chat.name.toLowerCase().includes(trimmed) && chat.userId === params.id) return true
      if (chat.characters.some((c) => c.name.toLowerCase().includes(trimmed))) return true
      if (chat.characters.some((c) => c.description.toLowerCase().includes(trimmed))) return true
      return false
    })
  })

  const chatsPager = usePagination({
    name: 'user-profile-chat-list',
    items: chats,
    pageSize: 48,
  })

  const chatSortOptions = createMemo(() => {
    const opts = baseSortOptions.slice()
    const hasCounts = chatState.publicChats.some((c) => !!c.messageCount)

    if (hasCounts) {
      opts.push({ value: 'chat-count', label: 'Chat Counts', kind: 'chat' })
    }

    return opts
  })

  const tabs = useTabs(['Public Characters', 'Public Chats'])

  const [view, setView] = createSignal('cards')
  const [charSortField, setCharSortField] = createSignal('chat-updated' as SortField)
  const [charSearch, setCharSearch] = createSignal('')

  const tags = tagStore((s) => ({ filter: s.filter, hidden: s.hidden }))

  const sortedChars = createMemo(() => {
    const field = charSortField()
    const dir = sortDirection()
    const sorted = characters()
      .slice()
      .filter((ch) => ch.userId === params.id)
      .filter((ch) => ch.name.toLowerCase().includes(charSearch().toLowerCase().trim()))
      .filter((ch) => tags.filter.length === 0 || ch.tags?.some((t) => tags.filter.includes(t)))
      .filter((ch) => !ch.tags || !ch.tags.some((t) => tags.hidden.includes(t)))
      .sort(getSortFunction(field, dir))
    return sorted
  })

  const charPager = usePagination({
    name: 'user-profile-character-list',
    items: sortedChars,
    pageSize: 48,
  })

  const favorites = createMemo(() => {
    const user = userStore()

    const field = charSortField()
    const dir = sortDirection()
    return characters()
      .filter(
        (ch) =>
          user.user?.favoritedPubChars?.includes(ch._id) ||
          (ch.userId === user.user?._id && ch.favorite)
      )
      .filter((ch) => ch.name.toLowerCase().includes(charSearch().toLowerCase().trim()))
      .filter((ch) => tags.filter.length === 0 || ch.tags?.some((t) => tags.filter.includes(t)))
      .filter((ch) => !ch.tags || !ch.tags.some((t) => tags.hidden.includes(t)))
      .sort(getSortFunction(field, dir))
  })

  onMount(() => {
    if (!params.id) {
      setComponentPageTitle(`User Profile`)
      return
    }

    setComponentPageTitle(
      state.viewingProfile ? `${state.viewingProfile.handle}'s User Profile` : 'User Profile'
    )
    characterStore.setViewingProfile(params.id)

    if (sortField() === 'character-name' || sortField() === 'character-created') {
      setSortField('chat-updated')
    }
  })

  createEffect(() => {
    setCharacters(chatStore().allChars.pubChars)
  })

  return (
    <Page class="flex flex-col gap-2">
      <Show when={state.viewingProfile}>
        <PageHeader
          title={
            <div class="flex w-full justify-between">
              <div>{state.viewingProfile!.handle}</div>
            </div>
          }
        />
        <img src={getAssetUrl(state.viewingProfile!.avatar!)} class="h-fit w-1/3 object-cover" />

        <div class="mb-2">
          <Tabs tabs={tabs.tabs} selected={tabs.selected} select={tabs.select} />
        </div>

        <Show when={tabs.selected() === 0}>
          <div class="flex flex-wrap">
            <div class="m-1 ml-0 mr-1">
              <TextInput
                fieldName="user-profile-char-search"
                placeholder="Search by name..."
                onKeyUp={(ev) => setCharSearch(ev.currentTarget.value)}
              />
            </div>

            <div class="flex flex-wrap">
              <Select
                class="m-1 ml-0 bg-[var(--bg-600)]"
                fieldName="sortBy"
                items={sortOptions}
                value={charSortField()}
                onChange={(next) => setCharSortField(next.value as SortField)}
              />

              <div class="mr-1 py-1">
                <Button
                  schema="secondary"
                  class="rounded-xl"
                  onClick={() => {
                    const next = sortDirection() === 'asc' ? 'desc' : 'asc'
                    setSortDirection(next)
                  }}
                >
                  {sortDirection() === 'asc' ? <SortAsc /> : <SortDesc />}
                </Button>
              </div>
            </div>

            <TagSelect class="m-1" characters={chatState.chars} />

            <div class="ml-auto">
              <Button
                schema="secondary"
                onClick={() => setView(view() === 'cards' ? 'list' : 'cards')}
              >
                <Switch>
                  <Match when={view() === 'cards'}>
                    <span class="hidden sm:block">List View</span> <LayoutList />
                  </Match>
                  <Match when={view() === 'list'}>
                    <span class="hidden sm:block">Cards View</span> <Image />
                  </Match>
                </Switch>
              </Button>
            </div>
          </div>

          <Characters
            allCharacters={sortedChars()}
            characters={charPager.items()}
            tagChars={chatState.chars}
            loading={state.loading || false}
            loaded={!!chatState.loaded}
            type={view() as ViewType}
            filter={chatSearch()}
            sortField={charSortField()}
            sortDirection={sortDirection()}
            favorites={favorites()}
          />
          <div class="flex justify-center pb-5 pt-2">
            <ManualPaginate pager={charPager} />
          </div>
        </Show>

        <Show when={tabs.selected() === 1}>
          <div class="mb-2 flex justify-between">
            <div class="flex flex-wrap gap-1">
              <div>
                <TextInput
                  fieldName="user-profile-chat-search"
                  placeholder="Search..."
                  onKeyUp={(ev) => setChatSearch(ev.currentTarget.value)}
                />
              </div>
              <div class="flex flex-wrap gap-1">
                <Select
                  class="bg-[var(--bg-600)]"
                  fieldName="sortBy"
                  items={chatSortOptions().filter(
                    (opt) => opt.kind === 'chat' && opt.value !== 'bot-activity'
                  )}
                  value={sortField()}
                  onChange={(next) => setSortField(next.value as SortType)}
                />

                <div>
                  <Button
                    schema="secondary"
                    class="rounded-xl"
                    onClick={() => {
                      const next = sortDirection() === 'asc' ? 'desc' : 'asc'
                      setSortDirection(next as SortDirection)
                    }}
                  >
                    {sortDirection() === 'asc' ? <SortAsc /> : <SortDesc />}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Show when={chatsPager.items().length} fallback={<NoChats />}>
            <Chats
              allChars={chatState.charsMap}
              chats={chatsPager.items()}
              chars={chatState.chars}
              sortField={sortField()}
              sortDirection={sortDirection()}
            />
          </Show>

          <div class="flex justify-center pb-5 pt-2">
            <ManualPaginate pager={chatsPager} />
          </div>
        </Show>
      </Show>
    </Page>
  )
}

export default UserProfilePage
