import { Component, For, Match, Show, Switch, createMemo, createSignal } from 'solid-js'
import { CardProps, ListCharacter } from './types'
import { A, useNavigate } from '@solidjs/router'
import AvatarContainer from '/web/shared/Avatar/Container'
import { getAssetUrl } from '/web/shared/util'
import {
  ChevronLeft,
  ChevronRight,
  Copy,
  Download,
  Edit,
  Menu,
  MessageCircle,
  MessageSquare,
  Star,
  Trash,
  VenetianMask,
} from 'lucide-solid'
import { DropMenu } from '/web/shared/DropMenu'
import Button from '/web/shared/Button'
import { tagStore, userStore } from '/web/store'
import { isLoggedIn } from '/web/store/api'
import { markdown } from '/web/shared/markdown'
import { AppSchema } from '/common/types'
import { usePagination } from '/web/shared/Paginate'
import { getSortFunction } from '../CharacterList'
import { Pill } from '/web/shared/Card'

export const CharacterCatView: Component<{
  toggleFavorite: (id: string, state: boolean, users: boolean) => void
  setDelete: (char: ListCharacter) => void
  setDownload: (char: ListCharacter) => void
  setEdit: (char: ListCharacter) => void
  characters: AppSchema.Character[]
  chatCounts: Record<string, number>
  mainPage?: boolean
}> = (props) => {
  const user = userStore()

  const popularPager = usePagination({
    name: 'hp-popular-pager',
    items: () =>
      props.characters.sort((a, b) => props.chatCounts[a._id] - props.chatCounts[b._id]).reverse(),
    pageSize: 8,
  })

  const newPager = usePagination({
    name: 'hp-new-pager',
    items: () => props.characters.sort(getSortFunction('modified', 'desc')),
    pageSize: 8,
  })

  const femalePager = usePagination({
    name: 'hp-female-pager',
    items: () =>
      props.characters.filter((c) => c.tags?.map((v) => v.toLowerCase()).includes('female')),
    pageSize: 8,
  })

  const malePager = usePagination({
    name: 'hp-male-pager',
    items: () =>
      props.characters.filter((c) => c.tags?.map((v) => v.toLowerCase()).includes('male')),
    pageSize: 8,
  })

  const groups = createMemo(() => {
    const groups = [
      {
        label: 'Popular',
        pager: popularPager,
      },
      {
        label: 'Female',
        pager: femalePager,
      },
      {
        label: 'Male',
        pager: malePager,
      },
      {
        label: 'New',
        pager: newPager,
      },
    ]
    return groups
  })

  return (
    <For each={groups()}>
      {(group, i) => (
        <Show when={group.pager.items().length > 0}>
          <>
            <h2 class="text-xl font-bold">{group.label}</h2>
            <div class="flex w-full flex-row flex-nowrap justify-start gap-2 overflow-x-scroll py-2">
              <Show when={group.pager.page() > 1}>
                <div class="mb-auto mt-auto">
                  <Button size={'md'} schema="secondary" class="px-2" onClick={group.pager.prev}>
                    <ChevronLeft size={20} />
                  </Button>
                </div>
              </Show>
              <For each={group.pager.items()}>
                {(char) => (
                  <Character
                    edit={() => props.setEdit(char)}
                    char={char}
                    delete={() => props.setDelete(char)}
                    download={() => props.setDownload(char)}
                    toggleFavorite={(value) =>
                      props.toggleFavorite(char._id, value, char.userId === user.user?._id)
                    }
                    chatCount={props.chatCounts[char._id]}
                  />
                )}
              </For>
              <Show when={group.pager.page() !== group.pager.pageSize()}>
                <div class="mb-auto mt-auto">
                  <Button size={'md'} schema="secondary" class="px-2" onClick={group.pager.next}>
                    <ChevronRight size={20} />
                  </Button>
                </div>
              </Show>
              <Show when={group.pager.items().length < 4}>
                <For each={new Array(4 - group.pager.items().length)}>{() => <div></div>}</For>
              </Show>
            </div>
          </>
        </Show>
      )}
    </For>
  )
}

export const CharacterTags: Component<{
  char: AppSchema.Character
}> = (props) => {
  const state = tagStore()

  const toggle = (value: string) => {
    tagStore.toggle(value, true)
  }

  return (
    <div class="my-2 flex h-16 flex-wrap justify-center gap-1 overflow-y-scroll px-1">
      <For each={props.char.tags}>
        {(tag) => (
          <Pill
            inverse={!state.filter.includes(tag)}
            small
            type="hl"
            onClick={() => toggle(tag)}
            class="h-[16px] max-w-[80px] cursor-pointer text-[10px]"
          >
            <span class="ellipsis">{tag.replace(' ', ' ')}</span>
          </Pill>
        )}
      </For>
    </div>
  )
}

const TinyPill: Component<{ children: any }> = (props) => (
  <Pill small inverse class="z-0 px-1" type="bg" opacity={0.4}>
    {props.children}
  </Pill>
)

const Character: Component<CardProps & { chatCount: number }> = (props) => {
  const [opts, setOpts] = createSignal(false)
  const nav = useNavigate()

  let ref: any
  const user = userStore()

  const users = props.char.userId === user.user?._id

  const formatter = Intl.NumberFormat('en', { notation: 'compact' })

  return (
    <div
      ref={ref}
      class="bg-800 flex flex-col items-center justify-between gap-1 rounded-lg border-[1px] border-[var(--bg-700)]"
    >
      <div class="relative w-full text-sm">
        <div class="absolute left-0 mx-1 mt-1 justify-start">
          <TinyPill>
            <MessageSquare size={24} color="var(--bg-100)" class="mr-1" />
            {formatter.format(props.chatCount || 0)}
          </TinyPill>
        </div>
        {/* hacky positioning shenanigans are necessary as opposed to using an
            absolute positioning because if any of the DropMenu parent is
            positioned, then DropMenu breaks because it relies on the nearest
            positioned parent to be the sitewide container */}
        <div class="absolute right-0 mr-1 mt-1 justify-end" onClick={() => setOpts(true)}>
          <div class="rounded-md border-[1px] border-[var(--bg-400)] bg-[var(--bg-700)] p-[2px]">
            <Menu size={24} class="icon-button" color="var(--bg-100)" />
          </div>
          <DropMenu
            show={opts()}
            close={() => setOpts(false)}
            customPosition="right-[9px] -top-[12px]"
          >
            <div class="flex flex-col gap-2 p-2">
              <Button
                onClick={() =>
                  props.toggleFavorite(
                    users
                      ? !props.char.favorite
                      : !user.user?.favoritedPubChars?.includes(props.char._id),
                    users
                  )
                }
                size="sm"
                alignLeft
              >
                <Show
                  when={
                    (props.char.userId === user.user?._id && props.char.favorite) ||
                    (props.char.userId !== user.user?._id &&
                      user.user?.favoritedPubChars?.includes(props.char._id))
                  }
                >
                  <Star class="text-900 fill-[var(--text-900)]" /> Unfavorite
                </Show>
                <Show
                  when={
                    (props.char.userId === user.user?._id && !props.char.favorite) ||
                    (props.char.userId !== user.user?._id &&
                      !user.user?.favoritedPubChars?.includes(props.char._id))
                  }
                >
                  <Star /> Favorite
                </Show>
              </Button>

              <Show when={isLoggedIn() || props.char.userId === user.user?._id}>
                <Button onClick={() => nav(`/chats/create/${props.char._id}`)} alignLeft size="sm">
                  <MessageCircle /> Chat
                </Button>
              </Show>
              <Button
                alignLeft
                size="sm"
                onClick={() => {
                  setOpts(false)
                  props.download()
                }}
              >
                <Download /> Download
              </Button>
              <Show when={props.char.userId === user.user?._id}>
                <Button alignLeft onClick={props.edit} size="sm">
                  <Edit /> Edit
                </Button>
              </Show>
              <Button
                alignLeft
                onClick={() => nav(`/character/create/${props.char._id}`)}
                size="sm"
              >
                <Copy /> Duplicate
              </Button>
              <Show when={props.char.userId === user.user?._id}>
                <Button
                  alignLeft
                  size="sm"
                  schema="red"
                  onClick={() => {
                    setOpts(false)
                    props.delete()
                  }}
                >
                  <Trash /> Delete
                </Button>
              </Show>
            </div>
          </DropMenu>
        </div>
      </div>

      <div class="h-full w-48">
        <Switch>
          <Match when={props.char.visualType === 'sprite' && props.char.sprite}>
            <A
              href={`/character/${props.char._id}/chats`}
              class="block h-32 w-full justify-center overflow-hidden rounded-lg"
            >
              <AvatarContainer container={ref} body={props.char.sprite} />
            </A>
          </Match>
          <Match when={props.char.avatar}>
            <A
              href={
                props.char.chat
                  ? `/chat/${props.char.chat._id}`
                  : `/character/${props.char._id}/chats`
              }
              class="block h-36 w-full justify-center overflow-hidden rounded-lg rounded-b-none"
            >
              <img
                src={getAssetUrl(props.char.avatar!)}
                class="h-full w-full object-cover"
                style="object-position: 50% 40%;"
              />
            </A>
          </Match>
          <Match when>
            <A
              href={`/character/${props.char._id}/chats`}
              class="bg-700 flex h-32 w-full items-center justify-center rounded-lg rounded-b-none"
            >
              <VenetianMask size={24} />
            </A>
          </Match>
        </Switch>
        <div class="mt-2 overflow-hidden text-ellipsis whitespace-nowrap px-1 text-center font-bold">
          {props.char.name}
        </div>

        <Show when={props.char.description}>
          <div
            class="text-600 rendered-markdown line-clamp-3 max-h-12 text-ellipsis text-center text-xs font-normal"
            innerHTML={markdown.makeHtml(
              props.char.description!.length >= 50
                ? props.char.description?.substring(0, 50)!
                : props.char.description!
            )}
          />
        </Show>

        <Show when={props.char.tags && props.char.tags.length > 0}>
          <CharacterTags char={props.char} />
        </Show>
      </div>
    </div>
  )
}
