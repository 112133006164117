import { Menu } from 'lucide-solid'
import { Component, Show } from 'solid-js'
import { A } from '@solidjs/router'
import { chatStore, settingStore } from '../store'
import { isChatPage } from './hooks'
import { getRgbaFromVar } from './colors'

const NavBar: Component = () => {
  const chats = chatStore((s) => ({
    chat: s.active?.chat,
    char: s.active?.char,
    loaded: s.loaded,
    opts: s.opts,
  }))

  const isChat = isChatPage()

  const Title = (
    <A href="/">
      <div class="flex w-full justify-center">
        Emanator<span class="text-[var(--hl-500)]">AI</span>
      </div>
    </A>
  )

  return (
    <Show when={!isChat()}>
      <div
        class="icon-button absolute left-2 z-50 rounded-md px-2 py-2"
        style={{ background: getRgbaFromVar('bg-700', 0.3)?.background }}
        onClick={() => settingStore.menu()}
      >
        <Menu class="focusable-icon-button cursor-pointer" color="var(--blue-400)" size={32} />
      </div>
      <div
        data-header=""
        class={`bg-900 sm:none flex h-[48px] justify-between gap-4 border-b-2 border-[var(--bg-800)] px-4 py-3 max-sm:p-1 sm:hidden`}
      >
        <div class="mx-auto flex w-full max-w-5xl items-center justify-between gap-2 font-semibold sm:justify-start">
          <div class="ellipsis flex w-full flex-col">
            <Show when={isChat()} fallback={Title}>
              <span class="w-full text-center text-[0.6rem]">
                Emanator<span class="text-[var(--hl-500)]">AI</span>
              </span>
              <span class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center">
                {chats.loaded ? chats.char?.name : '...'}
              </span>
            </Show>
          </div>
          <div class="w-8 sm:hidden"></div>
        </div>
      </div>
    </Show>
  )
}
export default NavBar
