import { Component } from 'solid-js'

const AgnaisticSettings: Component = () => {
  return (
    <>
      <div class="text-xl">Emanator Text To Speech</div>
    </>
  )
}

export default AgnaisticSettings
